$blue: #00aeef;
$gray-600: #737373;

@import "fonts";
@import "whiteframe";
@import "table";

@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap";
@import "~animate.css/animate.css";

body{
    color: #777777;
    background-color: #ecf0f1;
}

.clearfix{
    clear: both;
}

.navbar{
    border-bottom: 1px solid #ECF0F1;

    .logo{
        &.logo-feedback{
            height: 50px;
        }

        &.logo-divider{
            background-color: #ecf0f1;
            width: 1px;
            height: 40px;
            margin-left: 20px;
            margin-right: 20px;
        }

        &.logo-hsl{
            height: 40px;
        }
    }

    .avatar{
        .avatar-btn{
            background-color: #ecf0f1;
            width: 45px;
            height: 45px;
            display: block;
            line-height: 45px;
            text-align: center;
            border-radius: 50%;
            cursor: pointer;

            svg{
                width: 50%;
            }
        }

        .dropdown-menu{
            margin-left: -55px !important;
            margin-top: 10px !important;

            a{
                color: inherit;
                text-decoration: inherit;

                &:focus{
                    color: #FFFFFF;
                }
            }
        }
    }
}

.content{
  margin: 110px 0 110px 0;
  min-height: 430px;
}

footer{
    width: 100%;
    text-align: center;
    background-color: #0061AF;
    color: #FFFFFF;

    .footer{
        padding: 40px 0;
        font-size: 14px;
        text-transform: uppercase;

        p{
            margin: 0;
        }

        .responser{
            line-height: 54px;
        }

        .help{
            margin-top: 6px;

            .title{
                font-weight: 500;
            }
        }
    }

    img{
        width: 200px;
    }
}


@media only screen and (min-width : 1200px) {
    .container-extends { max-width: 1400px; }
}

@import "dashboard";
@import "auth";

@import "hacks";
