.dashboard{
    .notification{
        margin-top: -20px;
        margin-bottom: 25px;
    }

    .identification-row{
        min-height: 36px;
    }

    .indicators{
        .loading{
            position: fixed;
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 1;
            top: 0px;
            left: 0;
            background-color: rgba(255, 255, 255, 0.5);
            vertical-align: center;

            .spinner-border-lg {
                position: absolute;
                width: 100px;
                height: 100px;
                top: 50%;
                left: 50%;
                margin-left: -50px;
                margin-top: -50px;
                z-index: 2;
            }
        }
    }

    .h-divider{
        &.colors-divider{
            width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;

            .color{
                display: inline-block;
                height: 20px;
                width: 18%;
                position: relative;

                &.active:before{
                    content:"\A";
                    border-style: solid;
                    border-width: 10px 15px 10px 0;
                    border-color: transparent #FFFFFF transparent transparent;
                    position: absolute;
                    left: 50%;
                    margin-left: -5px;
                    top: -3px;
                    transform: rotate(-90deg);
                }

                &.color-one{
                    background-color: #00AEEF;
                }

                &.color-two{
                    background-color: #0095DA;
                }

                &.color-three{
                    background-color: #007DC5;
                }

                &.color-four{
                    background-color: #0061AF;
                }

                &.color-five{
                    background-color: #004992;
                }
            }

            .numbers {
                font-weight: 500 !important;
                display: inline-block;
                width: 5%;
                text-align: center;
                height: 20px;
            }
        }
    }

    .employee{
        &.identification{
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 500;
            color: #606164;

            .specialty{
                font-size: 16px;
                margin-left: 20px;
                color: #06AEEE;
                position: relative;

                &:before{
                    content: "";
                    position: absolute;
                    left: -20px;
                    width: 1px;
                    background-color: #06AEEE;
                    margin-left: 10px;
                    height: 100%;
                    top: 0;
                }
            }
        }
    }


    .filter{
        margin-bottom: 35px;

        &.filter-periods{
            margin-top: 10px;

            svg{
                width: 20px;
                margin-right: 5px;
                margin-top: -7px;
            }
        }
    }

    .page-brand{
        text-align: center;
        font-size: 20px;
        color: $blue;
    }

    .scores{
        .metrics-group{
            margin: 10px;
            padding: 15px;
            display: block;
            float: left;
            background-color: #FFFFFF;
            box-shadow: $whiteframe-shadow-1dp;
            border-radius: 5px;
            position: relative;

            .title{
                margin-bottom: 5px;
                text-transform: uppercase;
                font-size: 12px;
                max-width: 260px;

                small{
                    display: block;
                    font-size: 10px;
                    margin-top: 2px;
                }

                img.tooltip-info-icon{
                    position: relative;
                    display: inline-block;
                    left: inherit;
                    bottom: inherit;
                    right: inherit;
                    margin-left: 5px;
                    margin-top: -3px;
                    filter: invert(53%) sepia(3%) saturate(16%) hue-rotate(44deg) brightness(87%) contrast(84%);
                }
            }



            img{
                position: absolute;
                max-width: 80px;
                right: 10px;
                bottom: 10px;
            }

            &.cost{
                img{
                    // top: 30px;
                    // left: 20px;
                    // right: none;
                    // bottom: none;
                    z-index: 10;
                }
            }

            .subtitle, .hint{
                text-transform: uppercase;
                font-size: 10px;
            }

            .hint{
                color: $danger;
                display: block;
                margin: 0;
            }

            .details{
                margin-right: 90px;

                .metric{
                    height: auto;
                    width: 80px;
                    margin-left: 7px;

                    &.table-custom{
                        margin: 0;
                    }

                    &.circle{
                        height: 80px;
                        padding-top: 17px;

                        .title{
                            font-size: 8px;

                            small {
                                font-size: 8px;
                                font-weight: 600;
                                margin-top: -2px;
                            }

                            img {
                                position: relative;
                                right: auto;
                                bottom: -4px;
                            }

                        }
                    }

                    .title{
                        font-size: 10px;
                        .change-my-color {
                            filter: invert(53%) sepia(3%) saturate(16%) hue-rotate(44deg) brightness(87%) contrast(84%);
                        }
                    }

                    &:not(.circle){
                        .title{
                            position: relative;
                            img{
                                left: 50%;
                                top: 20px;
                                margin-left: -8px;
                            }
                        }
                    }


                    &.table{
                        width: auto;
                        margin-right: 10px;
                    }
                }

                .metric:first-child{
                    margin-left: 0;
                }
            }

            .only-one{
                .details{
                    .metric:first-child{
                        width: 150px;
                        margin: 0 auto;
                        float: none;
                    }
                }
            }
        }

        h2{
            text-transform: uppercase;
            font-size: 22px;
            font-weight: 500;
            text-align: center;
            color: #737373;
            margin: 40px 0;

            .year{
                display: inline-block;
                color: #0095DA;
                font-weight: 500;
                position: relative;
                padding-left: 20px;

                &:before{
                    position:absolute;
                    left: 10px;
                    height: 80%;
                    top: 5%;
                    width: 3px;
                    background-color: #737373;
                    content: "";
                }
            }
        }

         &.scores-general{
            .general-group{
                float: left;
                width: 170px;
                text-align: center;
                margin-right: 10px;
                align-items: center;
                display: flex;

                img{
                    width: 80%;
                }

                .title{
                    margin-top: 10px;
                    font-size: 14px;
                }
            }
        }
    }

    p.info{
        font-size: 10px;
        text-align: left;
    }

    p.infoLegenda {
        font-size: 12px;
        text-align: left;
        padding-right: 80px;
    }

    p.infoBox {
        font-size: 10px;
        text-align: left;
    }

    .metric-group, .index-group{
        .description{
            font-size: 12px;
            min-height: 72px;
            margin: 0;
            max-width: 150px;

        }

        .metric{
            width: 70px;
            float: left;
            text-align: center;
            height: 100px;
            margin-left: 10px;
            padding-top: 5px;

            &.circle{
                height: 70px;
                width: 70px;
                border: 1px solid #06aeee;
                border-radius: 50%;
                padding: 10px;
                margin-top: 2px;

                .value{
                    font-size: 18px;
                }

                .title{
                    font-size: 7px;
                }
            }

            .value{
                font-size: 30px;
                display: block;
                color: #0095DA;
            }

            .title{
                text-transform: uppercase;
                display: block;
                font-size: 8px;
            }
        }
    }

    .hospitalized, .ambulatory{
        .metric:first-child {
            .value{
                color: #0061AF;
            }
        }
    }

    .gravity{
        .info{
            margin-right: 90px;
            max-width: 430px;
        }
    }

    .diagnostic, .gravity, .mortality{
        .metrics{
            align-items: center;
            display: flex;

            .metric{
                width: auto;

                td{
                    vertical-align: middle;

                    &.title{
                        display: table-cell;
                        text-align: left;
                        font-size: 11px;
                    }

                    &.value{
                        display: table-cell;
                        text-align: center;
                        font-size: 22px;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    .medical-record{
        &.metrics-group{
            .details{
                .metric:first-child{
                    width: 150px;
                    margin: 0 auto;
                }
            }
        }
    }

    .prophylaxis{
        .legends{
            margin: 10px auto;
            width: 150px;
            margin-right: 90px;

            .legend{
                float: left;
                width: 60px;

                .title, .value{
                    display: block;
                    text-align: center;
                    text-transform: uppercase;
                    color: #606164;
                }

                .title{
                    font-size: 8px;
                }

                .value{
                    font-size: 10px;
                }
            }
        }
    }

    .cost-and-revenue{
        max-height: 654px;
    }

    .error{
        margin: 40px 0;
        padding-top: 50px;
        text-align: center;

        .icon{
            svg{
                width: 80px;
                margin-bottom: 10px;
            }
        }
    }
}

.legend-graph-table{
    .title-box{
        text-align: center;
        font-weight: bold;
        padding: 0 0 4px 0;
    }

    .title{
        margin: 0 10px;
    }

    .icon{
        span{
            width: 10px;
            height: 10px;
            display: block;
            border-radius: 50%;
        }
    }
}
