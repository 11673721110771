.auth{
    width: 420px;
    height: 400px;
    position: absolute;
    top:  50%;
    left: 50%;
    margin-top: -300px;
    margin-left: -210px;
    box-shadow: $whiteframe-shadow-1dp;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 40px;

    .brand{
        text-align: center;
        margin-bottom: 20px;

        img{
            width: 160px;

            &.logo-feedback{
                margin-right: 20px;
            }
        }
    }
}