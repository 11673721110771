.table.table-custom tr th {
    text-transform: uppercase;
    font-size: 14px;
    padding: 2px 2px 0 2px;
    margin: 0;
    border: 2px solid #F8F9FA;
}

.table.table-custom td{
    background: #FFFFFF;
    padding: 2px 5px;
}
.table.table-custom tbody tr:first-child{
    box-shadow: none;
}
.table.table-custom tbody tr{
    border: 2px solid #F8F9FA;
    box-shadow: $whiteframe-shadow-1dp;
}

.table.table-custom tr:hover td{
    background-color: #F8F9FA;
}

.table.table-center tr th,
.table.table-center tr td{
    text-align: center;
    vertical-align: middle;
}

.table.table-center tr th.text-left,
.table.table-center tr td.text-left{
    text-left: left;
}

.table.table-center tr th.text-right,
.table.table-center tr td.text-right{
    text-left: right;
}