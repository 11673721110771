.animate__animated{
    &.animate__animated{
        animation-fill-mode: backwards;
    }
}

.tooltip-inner {
    white-space:pre-wrap;
    min-width: initial;
    max-width: 480px;
}
